<template>
  <div v-if="schema && schema.properties">
    <v-form ref="form" @submit="submit" @reset="reset" v-if="show">
      <FormJsonSchemaFields ref="formJsonSchema" v-model:model="modelLocal" :schema="schema" :domainmodel="domainmodel" />
    </v-form>
  </div>
</template>

<script lang="ts">
import FormJsonSchemaFields from './FormJsonSchemaFields.vue'
//import JsonSchemaVue from './JsonSchema.vue';
import { defineComponent } from 'vue'
export default defineComponent({
  components: { FormJsonSchemaFields },
  name: 'FormJsonSchema',
  props: {
    schema: Object /* json Schema */,
    model: Object /* { "email": "", "name": "",  "food": null, "checked": [] } */,
    show: {
      type: Boolean,
      default: true
    },
    cols: String,
    md: String,
    domainmodel: {
      type: String,
      default: 'please_enter_model_name'
    } // productGroup
  },
  computed: {
    /* The Vue pattern is props down and events up.
     * Parent comp:       <FormJsonSchema  :model.sync="model"  ..../>
     * Local computed var modelLocal
     * $emit parent update goes to the synced prop.
     */
    modelLocal: {
      get: function () {
        return this.model
      },
      set: function (value) {
        this.$emit('update', value)
      }
    }
  },
  methods: {
    generateTranslationString(name) {
      return this.domainmodel + '_' + name
    },
    submit() {
      this.$emit('submit')
    },
    reset() {
      this.$emit('reset')
    },

    jsonSchemaToInputTypeconverter(jsonSchemaType, jsonSchemaFormat) {
      switch (jsonSchemaType) {
        case 'number':
          return 'number'
        //break;
        case 'integer':
          return 'number'
        //break;
        case 'float':
          return 'number'
        //break;
        case 'date':
          return 'date'
        //break;
        case 'string':
          switch (jsonSchemaFormat) {
            case 'date':
              return 'date'
            default:
              console.error('Componente FormJsonSchema.vue / jsonSchemaToInputTypeconverter(jsonSchemaType = "' + jsonSchemaType + '") unbekannter Typ')
              return 'text'
          }
        default:
          console.error('Componente FormJsonSchema.vue / jsonSchemaToInputTypeconverter(jsonSchemaType = "' + jsonSchemaType + '") unbekannter Typ')
          return 'text'
        // break
      }
    }
  }
  /* 
  mounted() { }
  */
})
</script>
