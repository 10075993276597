<template>
  <v-row>
    <v-col :cols="cols ? cols : 12" :md="md ? md : 6" v-for="(property, name) in schema.properties" v-bind:key="name">
      <v-text-field
        :id="'input-' + name"
        v-model="modelLocal[name]"
        :label="$t(generateTranslationString(name))"
        :type="jsonSchemaToInputTypeconverter(property.type, property.format)"
        :required="schema && schema.required && schema[property.name] ? 'required' : ''"
        :rules="property.required ? [rules.required] : []"
        :placeholder="property.attrs && property.attrs.placeholder ? property.attrs.placeholder : ''"
        :disabled="property && property.disabled"
        :readonly="property && property.readonly"
        :step="property.attrs && property.attrs.step ? property.attrs.step : '0.01'"
      ></v-text-field>
    </v-col>
  </v-row>
</template>

<script lang="ts">
//import JsonSchemaVue from './JsonSchema.vue';
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'FormJsonSchemaFields',
  props: {
    schema: Object /* json Schema */,
    model: Object /* { "email": "", "name": "",  "food": null, "checked": [] } */,
    cols: String,
    md: String,
    domainmodel: {
      type: String,
      default: 'please_enter_model_name'
    } // productGroup
  },
  data() {
    return {
      rules: {
        required: (value) => !!value || this.$t('form_json_schema_required_field')
      }
    }
  },
  computed: {
    /* The Vue pattern is props down and events up.
     * Parent comp:       <FormJsonSchema  :model.sync="model"  ..../>
     * Local computed var modelLocal
     * $emit parent update goes to the synced prop.
     */
    modelLocal: {
      get: function () {
        return this.model
      },
      set: function (value) {
        this.$emit('update', value)
      }
    }
  },
  methods: {
    generateTranslationString(name) {
      return this.domainmodel + '_' + name
    },
    jsonSchemaToInputTypeconverter(jsonSchemaType, jsonSchemaFormat) {
      switch (jsonSchemaType) {
        case 'number':
          return 'number'
        //break;
        case 'integer':
          return 'number'
        //break;
        case 'float':
          return 'number'
        //break;
        case 'date':
          return 'date'
        //break;
        case 'string':
          switch (jsonSchemaFormat) {
            case 'date':
              return 'date'
            default:
              console.error('Componente FormJsonSchemaFields.vue / jsonSchemaToInputTypeconverter(jsonSchemaType = "' + jsonSchemaType + '") unbekannter Typ')
              return 'text'
          }
        default:
          console.error('Componente FormJsonSchemaFields.vue / jsonSchemaToInputTypeconverter(jsonSchemaType = "' + jsonSchemaType + '") unbekannter Typ')
          return 'text'
        // break
      }
    }
  }
  /* 
  mounted() { }
  */
})
</script>
